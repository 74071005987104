<template>
  <ion-page class="ion-page">
    <ContentContainer>
      <div class="max-w-xl mx-auto">
        <Refresher @refresh="doRefresh($event, 'facilities')" />

        <List header-color="primary">
          <AdvertisementSlides ad-slot="top_facilities" margin-bottom />

          <ion-segment class="top" color="moto" value="facilities">
            <ion-segment-button value="facilities" key="facilities">
              {{ $t('facilities') | uppercase }}
            </ion-segment-button>
          </ion-segment>

          <ion-item>
            <ion-segment scrollable>
              <ion-button @click="openModal">
                <ion-text>{{ $t('create') }}</ion-text>
              </ion-button>
              <ion-button @click="openPopOverType">
                {{ $t('type') }}
              </ion-button>
            </ion-segment>
          </ion-item>

          <List
            :not-found-message="$t('nofacilitiesfound')"
            :loading="$apollo.loading && !facilities"
            :loading-more="loadingMore"
            @loadMore="loadMore($event, 'facilities')"
          >
            <template v-if="facilities && facilities.data">
              <FacilityItem v-for="facility in facilities.data" :key="facility.id" :facility="facility" @click.native="goToFacility(facility.id)" />
            </template>
          </List>
        </List>
      </div>
    </ContentContainer>
  </ion-page>
</template>

<style scoped>

  ion-segment.top ion-segment-button {
    max-width: 100%;
  }

  ion-segment.top ion-segment-button.segment-button-checked {
    color: var(--ion-color-white) !important;
    background: var(--ion-color-primary) !important;
    border-bottom: 2px solid var(--ion-color-moto);
  }

  ion-segment.top ion-segment-button {
    color: var(--ion-color-white) !important;
    background: var(--ion-color-primary) !important;
  }

</style>
<script>
    import MotoPopOver from '@/mixins/PopOver'
    import MakeFacilityModal from '@/components/modals/MakeFacilityModal'
    import { GET_FACILITIES } from "@/graphql/queries";
    import { infiniteScrollMixin, setUserPositionMixin } from '@/mixins';
    import { defaultPaginationConfig } from '@/configs';
    import AdvertisementSlides from "../../components/AdvertisementSlides";
    import FacilityItem from '@/components/items/FacilityItem';

    export default {
      components: {
        AdvertisementSlides,
        FacilityItem,
      },
      mixins: [
        MotoPopOver,
        infiniteScrollMixin,
        setUserPositionMixin,
      ],
      apollo: {
        facilities: {
          query: GET_FACILITIES,
          variables() {
            return {
              ...defaultPaginationConfig,
              type: this.selectedType,
              latitude: this.latitude,
              longitude: this.longitude,
            }
          },
        }
      },
      data() {
        return {
          types: [
            {
              id: 0,
              title: this.$t('everything'),
              system_name: null,
            },
            {
              id: 1,
              title: this.$t('club'),
              system_name: 'CLUB',
            },
            {
              id: 2,
              title: this.$t('trainer'),
              system_name: 'TRAINER'
            },
            {
              id: 3,
              title: this.$t('shop'),
              system_name: 'SHOP'
            }
          ],
          selectedType: null,
          popover: null,
        }
      },
      async created() {
          this.setUserPosition();
      },
      methods: {
        async doRefresh(event, model) {
          this.resetInfiniteScroll();
          await this.$apollo.queries[model].refetch();
          event.target.complete()
        },
        openModal() {
          return this.$ionic.modalController
            .create({
              component: MakeFacilityModal,
              componentProps: {
                parent: this,
                propsData: {
                  categories: this.categories,
                  closeMe: () => {
                    this.$ionic.modalController.dismiss()
                  }
                },
              },
            })
            .then(m => m.present())
        },
        openPopOverType(event) {
          this.popover = this.popOver(event, {
            title: "Types",
            items: this.types,
            selected: this.selectedType,
            selectItem: this.selectType
          });
        },
        goToFacility(id) {
          this.$router.push({ name: this.$routeNames.FACILITY, params: { id } })
        },
        selectType(item) {
          this.selectedType = item.system_name;
          this.resetInfiniteScroll();
        }
      }
    }
</script>
